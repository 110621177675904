import { ButtonIcon, MaterialChevronLeftSvg } from '@dce-front/dive';
import {
  KEY_BACK,
  Layer,
  useActiveLayer,
  useKeyCatcher,
  useStore,
} from '@dce-front/one-navigation';
import { useImmersiveNoScroll } from '@dce-front/onewebapp-util-react';
import { ModalV2 } from '@dce-front/sharedcomponent';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { useAppHistory } from '../../../helpers/hooks/reactRouter';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import { LAYER_VOD_FUNNEL } from '../../../helpers/oneNavigation/layers';
import { useTranslation } from '../../../lang';
import { FunnelInnerStep } from '../stores/constants';
import {
  useFunnelCurrentStep,
  useFunnelErrorTemplate,
  useFunnelHistory,
  useFunnelLoading,
  useFunnelOpen,
} from '../stores/funnel/hooks';
import FunnelContent from './FunnelContent';
import styles from './FunnelModal.css';
import { useHandleFunnelModalBack } from './hooks/useHandleFunnelModalBack/useHandleFunnelModalBack';

const cx = classNames.bind(styles);

type FunnelModalProps = {
  setIsOpen?: (openModal: boolean) => void;
  fullPage?: boolean;
};

const SPINNER_SIZE = 5; // 5rem
const SPINNER_SIZE_TV = 5.5; // 5.5rem
const FUNNEL_MODAL_ID = 'modal_funnel_tvod';

export function FunnelModal({
  setIsOpen,
  fullPage,
}: FunnelModalProps): JSX.Element {
  const { t } = useTranslation();
  const store = useStore();
  const history = useAppHistory();

  const funnelHistory = useFunnelHistory();
  const currentStep = useFunnelCurrentStep();
  const isLoading = useFunnelLoading();
  const isOpen = useFunnelOpen();
  const errorTemplate = useFunnelErrorTemplate();

  const isFirstStep = funnelHistory?.length === 1;
  const isTermsOfSale = currentStep.innerStep === FunnelInnerStep.TermsOfSale;

  const handleCloseModal = useCallback(() => {
    const immersiveCallback =
      history.location.state?.immersive?.mainOnClick?.path?.includes(
        'callbackState',
      ) || false;
    const pageCallback =
      history.location.state?.page?.mainOnClick?.path?.includes(
        'callbackState',
      ) || false;

    // On CSR, we only go back to the detailPage
    if (!fullPage && !immersiveCallback && !pageCallback) {
      history.replace(
        history.location.state?.immersive?.mainOnClick?.path ||
          history.location.state?.page?.mainOnClick?.path ||
          '..',
        history.location.state,
      );
    }
    // Close funnel modal
    setIsOpen?.(false);
  }, [setIsOpen, history, fullPage]);

  const handleFunnelModalBack = useHandleFunnelModalBack(
    handleCloseModal,
    fullPage,
  );

  useEffect(() => {
    if (!isOpen) {
      handleCloseModal();
    }
  }, [isOpen, handleCloseModal]);

  // FIXME: why not using the parent focusMananger ?
  const focusManager = useMemo(() => new FocusManager(store), [store]);

  useActiveLayer(LAYER_VOD_FUNNEL);
  useKeyCatcher(KEY_BACK, handleFunnelModalBack, LAYER_VOD_FUNNEL);
  useImmersiveNoScroll({ prefix: 'immersive' });
  return fullPage ? (
    <div
      id={FUNNEL_MODAL_ID}
      className={cx('funnelModal', { 'funnelModal--fullPage': fullPage })}
    >
      {!errorTemplate && !$_BUILD_RENDERMODE_CSR && currentStep.url && (
        <ButtonIcon
          className={cx('funnelModal__backButton')}
          icon={<MaterialChevronLeftSvg />}
          onClick={handleFunnelModalBack}
          aria-label={t('Icon.back')}
        />
      )}
      <Layer layer={LAYER_VOD_FUNNEL}>
        {isLoading && (
          <div
            aria-hidden="true"
            className={cx('funnelModal__spinner__backdrop')}
          >
            <Spinner
              size={$_BUILD_RENDERMODE_CSR ? SPINNER_SIZE_TV : SPINNER_SIZE}
            />
          </div>
        )}
        <FunnelContent focusManager={focusManager} />
      </Layer>
    </div>
  ) : (
    <ModalV2
      id={FUNNEL_MODAL_ID}
      isTvDevice={$_BUILD_RENDERMODE_CSR}
      onBack={
        !isFirstStep && !errorTemplate ? handleFunnelModalBack : undefined
      }
      onClose={handleCloseModal}
      size={$_BUILD_RENDERMODE_CSR ? 'fullscreen' : 'immersive'}
      className={cx({
        // Disable pointer/mouse scroll on Terms of Sale
        'funnelModal--noScroll': $_BUILD_RENDERMODE_CSR && isTermsOfSale,
      })}
      classNameContainer={cx('funnelModal')}
      classNameBody={cx('funnelModal__body', {
        // Disable pointer/mouse scroll on Terms of Sale
        'funnelModal--noScroll': $_BUILD_RENDERMODE_CSR && isTermsOfSale,
      })}
      hideCloseButton={$_BUILD_RENDERMODE_CSR}
      hidePadding={$_BUILD_RENDERMODE_CSR}
      closeAriaLabel={t('Icon.close')}
      backAriaLabel={t('Icon.back')}
      preferScrollableBody={!$_BUILD_RENDERMODE_CSR}
    >
      <Layer layer={LAYER_VOD_FUNNEL}>
        {isLoading && (
          <div
            aria-hidden="true"
            className={cx('funnelModal__spinner__backdrop')}
          >
            <Spinner
              size={$_BUILD_RENDERMODE_CSR ? SPINNER_SIZE_TV : SPINNER_SIZE}
            />
          </div>
        )}
        <FunnelContent focusManager={focusManager} />
      </Layer>
    </ModalV2>
  );
}
