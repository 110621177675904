import type { ApiV2ContextualOfferPreviousPage } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { ApiV2StubPage } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/stub';
import type { ApiV2TvodPaymentError } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import type { FunnelStep, PaymentMean } from '../types';

export const ActionType = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_PREVIOUS_PAGE: 'SET_PREVIOUS_PAGE',
  INCREMENT_FUNNEL_HISTORY: 'INCREMENT_FUNNEL_HISTORY',
  AMEND_FUNNEL_HISTORY: 'AMEND_FUNNEL_HISTORY',
  DECREMENT_FUNNEL_HISTORY: 'DECREMENT_FUNNEL_HISTORY',
  ADD_PAYMENT_MEAN: 'ADD_PAYMENT_MEAN',
  RESET_PAYMENT_MEAN: 'RESET_PAYMENT_MEAN',
  SET_LOADING: 'SET_LOADING',
  SET_OPEN: 'SET_OPEN',
  SET_ERROR_TEMPLATE: 'SET_ERROR_TEMPLATE',
} as const;

export type ActionType = (typeof ActionType)[keyof typeof ActionType];

type ActionSetCurrentStep = {
  type: typeof ActionType.SET_CURRENT_STEP;
  payload: {
    currentStep: FunnelStep;
  };
};

type ActionSetPreviousPage = {
  type: typeof ActionType.SET_PREVIOUS_PAGE;
  payload: ApiV2ContextualOfferPreviousPage;
};

type ActionIncrementFunnelHistory = {
  type: typeof ActionType.INCREMENT_FUNNEL_HISTORY;
  payload: {
    funnelHistory: FunnelStep;
  };
};

type ActionAmendFunnelHistory = {
  type: typeof ActionType.AMEND_FUNNEL_HISTORY;
  payload: {
    newLastStep: FunnelStep;
  };
};

type ActionDecrementFunnelHistory = {
  type: typeof ActionType.DECREMENT_FUNNEL_HISTORY;
};

type ActionAddPaymentMean = {
  type: typeof ActionType.ADD_PAYMENT_MEAN;
  payload: {
    paymentMean: PaymentMean;
  };
};

type ActionResetPaymentMean = {
  type: typeof ActionType.RESET_PAYMENT_MEAN;
};

type ActionSetLoading = {
  type: typeof ActionType.SET_LOADING;
  payload: boolean;
};

type ActionSetOpen = {
  type: typeof ActionType.SET_OPEN;
  payload: boolean;
};

type ActionSetErrorTemplate = {
  type: typeof ActionType.SET_ERROR_TEMPLATE;
  payload?: ApiV2TvodPaymentError | ApiV2StubPage;
};

export type FunnelAction =
  | ActionSetCurrentStep
  | ActionSetPreviousPage
  | ActionDecrementFunnelHistory
  | ActionIncrementFunnelHistory
  | ActionAmendFunnelHistory
  | ActionAddPaymentMean
  | ActionResetPaymentMean
  | ActionSetLoading
  | ActionSetOpen
  | ActionSetErrorTemplate;

/**
 * Update current step with currentStep
 * It's trigger funnelContent that change the UI funnel template
 * @param currentStep funnel step
 */
export const setCurrentStep = (
  currentStep: FunnelStep,
): ActionSetCurrentStep => ({
  type: ActionType.SET_CURRENT_STEP,
  payload: { currentStep },
});

/**
 * Set hodor previous page (detailPage, contextualOffer, etc.)
 * @param previousPage previous page
 */
export const setPreviousPage = (
  previousPage: ApiV2ContextualOfferPreviousPage,
): ActionSetPreviousPage => ({
  type: ActionType.SET_PREVIOUS_PAGE,
  payload: previousPage,
});

/**
 * Increment history with the new funnel step
 * @param funnelHistory the new funnel step to add to funnelHistory context
 */
export const incrementFunnelHistory = (
  funnelHistory: FunnelStep,
): ActionIncrementFunnelHistory => ({
  type: ActionType.INCREMENT_FUNNEL_HISTORY,
  payload: { funnelHistory },
});

/**
 * update history with the new funnel step (usually useful for managing funnel InnerSteps)
 * @param funnelHistory the new funnel step to add to funnelHistory context
 */
export const amendFunnelHistory = (
  newLastStep: FunnelStep,
): ActionAmendFunnelHistory => ({
  type: ActionType.AMEND_FUNNEL_HISTORY,
  payload: { newLastStep },
});

/**
 * Decrement one step of funnel history and change currentStep with previous step
 */
export const decrementFunnelHistory = (): ActionDecrementFunnelHistory => ({
  type: ActionType.DECREMENT_FUNNEL_HISTORY,
});

/**
 * Add paymentMean to context (selected payment mean)
 * @param paymentMean payment mean to add (Turbo or Kiss)
 */
export const addPaymentMean = (
  paymentMean: PaymentMean,
): ActionAddPaymentMean => ({
  type: ActionType.ADD_PAYMENT_MEAN,
  payload: { paymentMean },
});

/**
 * Delete paymentMean context
 */
export const resetPaymentMean = (): ActionResetPaymentMean => ({
  type: ActionType.RESET_PAYMENT_MEAN,
});

/*
 * isLoading when we fetch data from Hodor
 */
export const setIsLoading = (value: boolean): ActionSetLoading => ({
  type: ActionType.SET_LOADING,
  payload: value,
});

/*
 * showModal when we fetch data from Hodor
 */
export const setIsOpen = (value: boolean): ActionSetOpen => ({
  type: ActionType.SET_OPEN,
  payload: value,
});

/*
 * set error template from hodor
 */
export const setErrorTemplate = (
  errorTemplate?: ApiV2TvodPaymentError | ApiV2StubPage,
): ActionSetErrorTemplate => {
  return {
    type: ActionType.SET_ERROR_TEMPLATE,
    payload: errorTemplate,
  };
};
