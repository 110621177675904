export const SOURCE_TURBO = 'turbo';

export const FunnelHodorStep = {
  /** Hodor contextual offer page */
  ContextualOffer: 'contextualOfferPage',
  /** Hodor PaymentMeans */
  PaymentMeans: 'paymentMeans',
  /** Hodor template paymentError with alertBox */
  PaymentError: 'paymentError',
} as const;

export type FunnelHodorStep =
  (typeof FunnelHodorStep)[keyof typeof FunnelHodorStep];

export const FunnelInnerStep = {
  /** last step payment to finalize payment */
  EndPayment: 'endPayment',
  /** safety code purchase screen */
  PurchaseCode: 'purchaseCode',
  /** voucher entry step for tv */
  Voucher: 'voucher',
  /** deported screen with QR code for tv */
  DeportedPayment: 'deportedPayment',
  /** textBrut template with terms of sale */
  TermsOfSale: 'termsOfSale',
  /** delete registered CB step for tv */
  DeleteCB: 'deleteCB',
} as const;

export type FunnelInnerStep =
  (typeof FunnelInnerStep)[keyof typeof FunnelInnerStep];

export const HapiStatus = {
  Complete: 'complete',
  Cancel: 'cancel',
  Pending: 'pending',
  Incomplete: 'incomplete',
} as const;

export type HapiStatus = (typeof HapiStatus)[keyof typeof HapiStatus];
