// query string key for redirect to detail page with popin status in payment invoice
export const QS_PROCESS_STATE = 'callbackState';
export const TVOD_ERROR = 'error';
export const TVOD_SUCCESS = 'success';

export const TypePrice = {
  Major: 'major',
  Minor: 'minor',
} as const;

export type TypePrice = (typeof TypePrice)[keyof typeof TypePrice];
